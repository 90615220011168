import { atom } from 'recoil'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { CategoryProfileResponse } from '../../types/users/category-profile-response'
import { CheckoutGiftingRequest } from '../../types/checkout/requests/checkout-gifting-request'
import { PaymentResponse } from '../../types/stripe/responses/stripe-payment-response'
import { CurrencyResponse } from '../../types/currencies/responses/currency-response'
import { TeamResponse } from '../../types/teams/responses/team-response'

export const gamesState = atom<CategoryResponse[]>({
  key: 'gamesState',
  default: [],
})

export const gameProfilesState = atom<CategoryProfileResponse[]>({
  key: 'gameProfilesState',
  default: [],
})

export const selectedPaymentState = atom<PaymentResponse | null>({
  key: 'selectedPaymentState',
  default: null,
})

export const clientTokenState = atom<string>({
  key: 'clientTokenState',
  default: '',
})

export const tipsModalInfoState = atom<CheckoutGiftingRequest>({
  key: 'tipsModalInfoState',
  default: {
    teammateId: 0,
    tipString: '',
    giftId: 0,
    requestId: 0,
    deviceData: '',
  },
})

export const selectedGameState = atom<CategoryResponse>({
  key: 'selectedGameState',
  default: {
    key: 'league-of-legends',
    name: 'League of Legends',
    active: false,
  } as CategoryResponse,
})

export const categoriesState = atom<CategoryResponse[]>({
  key: 'categoriesState',
  default: [],
})

export const isOpenDeclineState = atom<boolean>({
  key: 'isOpenDeclineState',
  default: false,
})

export const isOpenSendMessageState = atom<boolean>({
  key: 'isOpenSendMessageState ',
  default: false,
})

export const isOpenRefundState = atom<boolean>({
  key: 'isOpenRefundState',
  default: false,
})

export const isOpenBanInfoState = atom<boolean>({
  key: 'isOpenBanInfoState',
  default: false,
})

export const selectedTeammateState = atom<number>({
  key: 'selectedTeammateState',
  default: -1,
})

export const focusIdState = atom<string>({
  key: 'manualFocusId',
  default: '1',
})

export const candidateState = atom<string>({
  key: 'candidateState',
  default: '',
})

export const homeStepsState = atom<number>({
  key: 'homeStepsState',
  default: 1,
})

export const isOpenTipsConfirmModalState = atom<boolean>({
  key: 'isOpenTipsConfirmModalState',
  default: false,
})

export const isOpenCoachingState = atom<boolean>({
  key: 'isOpenCoachingState',
  default: false,
})

export const isOpenModeModalState = atom<boolean>({
  key: 'isOpenModeModalState',
  default: false,
})

export const tipsFeedbackFromTeammateState = atom<{
  id: number
  message: string
} | null>({
  key: 'tipsFeedbackFromTeammateState',
  default: null,
})

export const isOpenTipsOptionModalState = atom<boolean>({
  key: 'isOpenTipsOptionModalState',
  default: false,
})

export const isRefreshMatchHistoryState = atom<boolean>({
  key: 'isRefreshMatchHistoryState',
  default: false,
})

export const defaultCurrencyState = atom<CurrencyResponse | null>({
  key: 'defaultCurrencyState',
  default: null,
})

export const isOpenLPShieldModalState = atom<boolean>({
  key: 'isOpenLPShieldModal',
  default: false,
})

export const activeTeamState = atom<TeamResponse | undefined>({
  key: 'activeLobbyState',
  default: undefined,
})

export const activeInviteIdState = atom<string | undefined>({
  key: 'activeInviteState',
  default: undefined,
})
