import { atom } from 'recoil'
import { PromoCodeResponse } from '../../types/stripe/responses/promo-code-response'
import { ProductResponse } from '../../types/products/product-response'
import { RequestResponse } from '../../types/requests/responses/request-response'
import { Dictionary } from '../../types/collections/dictionary'
import { CategorySpecializedTag } from '../../types/users/requests/category-specialized-tag-response'
import { InGameAccountModalStatusEnum } from '../enums/in-game-account-modal-status.enum'
import { CompensateModal } from '../../types/courtesy/CompensateModal'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'
import { PreferencesResponse } from '../../types/preferances/responses/preferences-response'
import { TotalAmountResponse } from '../../types/checkout/responses/total-amount.response'
import { TeamResponse } from '../../types/teams/responses/team-response'
import { ChampionResponse } from '../../types/users/responses/champion-response'
import { LolAccountResponse } from '../../types/account/lol-account-response'

export const isOpenSignUpModalState = atom<boolean>({
  key: 'isOpenSignUpModalState',
  default: false,
})
export const isOpenGuestSignUpModalState = atom<boolean>({
  key: 'isOpenGuestSignUpModalState',
  default: false,
})

export const isOpenLogInModalState = atom<boolean>({
  key: 'isOpenLogInModalState',
  default: false,
})

export const quantityState = atom<number>({
  key: 'sessionQuantityState',
  default: 1,
})

export const promoState = atom<PromoCodeResponse | null>({
  key: 'promoCode',
  default: null,
})

export const selectedProductState = atom<ProductResponse | null>({
  key: 'selectedProductState',
  default: null,
})
export const selectedAccountProductState = atom<LolAccountResponse | null>({
  key: 'selectedAccountProductState',
  default: null,
})

export const selectedGameMode = atom<string>({
  key: 'selectedGameModeState',
  default: '',
})

export const selectedProductAddOnsState = atom<AddonResponse[]>({
  key: 'selectedProductAddOnsState',
  default: [],
})

export const productsState = atom<ProductResponse[]>({
  key: 'productsState',
  default: [],
})

// @ts-ignore
export const activeRequestState = atom<RequestResponse | null>({
  key: 'activeRequestState',
  default: null,
})

export const isOpenStripePaymentState = atom<boolean>({
  key: 'isOpenStripePaymentState',
  default: false,
})

export const isOpenGuestPaymentState = atom<boolean>({
  key: 'isOpenGuestPaymentState',
  default: false,
})

export const isOpenStripePaymentTipsState = atom<boolean>({
  key: 'isOpenStripePaymentTipsState',
  default: false,
})

export const isOpenWalletState = atom<boolean>({
  key: 'isOpenWalletState',
  default: false,
})

export const isOpenPreferenceState = atom<boolean>({
  key: 'isOpenPreferenceState',
  default: false,
})

export const isOpenReportState = atom<boolean>({
  key: 'isOpenReportState',
  default: false,
})
export const isOpenReportOtherModalSate = atom<boolean>({
  key: 'isOpenReportOtherModalSate',
  default: false,
})

export const isOpenRateOtherModalSate = atom<boolean>({
  key: 'isOpenRateOtherModalSate',
  default: false,
})

export const isOpenInGameAccountModalState = atom<boolean>({
  key: 'isOpenInGameAccountModalState',
  default: false,
})

export const inGameAccountFromState = atom<'modal' | 'checkout' | null>({
  key: 'addInGameAccountFromState',
  default: null,
})

export const paymentFromState = atom<'wallet' | 'checkout' | 'reload' | null>({
  key: 'paymentFromState',
  default: null,
})

export const InGameAccountModalState = atom<InGameAccountModalStatusEnum>({
  key: 'InGameAccountStatus',
  default: InGameAccountModalStatusEnum.ACCOUNTS,
})

export const PreferencesState = atom<PreferencesResponse>({
  key: 'PreferencesState',
  default: {},
})

export const categoryGroupTagsState = atom<
  Dictionary<CategorySpecializedTag[]>
>({
  key: 'categoryGroupTagsState',
  default: {},
})

export const selectCategoryProfileState = atom<number>({
  key: 'selectCategoryProfileState',
  default: -1,
})

export const isOpenConfirmBlockModalState = atom<boolean>({
  key: 'isOpenConfirmBlockModalState',
  default: false,
})

export const isNoAccountsState = atom<boolean>({
  key: 'isNoAccountsState',
  default: false,
})

export const tipsListState = atom<number>({
  key: 'tipsListState',
  default: -1,
})

export const alreadyTipsListState = atom<number[]>({
  key: 'alreadytipsListState',
  default: [],
})

export const isOpenConfirmDashboardState = atom<boolean>({
  key: 'isOpenConfirmDashboardState',
  default: false,
})

export const isOpenConfirmHandOverState = atom<boolean>({
  key: 'isOpenConfirmHandOverState',
  default: false,
})

export const isOpenUploadState = atom<boolean>({
  key: 'isOpenUploadState',
  default: false,
})

export const uploadingRequestState = atom<RequestResponse | null>({
  key: 'uploadingRequestState',
  default: null,
})

export const isOpenRatingState = atom<boolean>({
  key: 'isOpenRatingState',
  default: false,
})

export const isOpenCompensateModalState = atom<CompensateModal>({
  key: 'isOpenCompensateModalState',
  default: {
    isOpen: false,
    type: '',
  },
})

export const isShowThanksTipsSectionState = atom<boolean>({
  key: 'isShowThanksTipsSectionState',
  default: false,
})

export const isOpenUserAskToCancelNotificationState = atom<boolean>({
  key: 'isOpenUserAskToCancelNotificationState',
  default: false,
})

export const lostCourtesyCreditsState = atom<number>({
  key: 'lostCourtesyCreditsState',
  default: 4.99,
})

export const tipsState = atom<{ tips: number; id: number }>({
  key: 'tipsState',
  default: {
    tips: 0,
    id: -1,
  },
})

export const isOpenPromoCodeState = atom<boolean>({
  key: 'isOpenPromoCodeState',
  default: false,
})

export const isOpenPromoCodeManagementState = atom<boolean>({
  key: 'isOpenPromoCodeManagementState',
  default: false,
})

export const isOpenConfirmModalState = atom<boolean>({
  key: 'isOpenConfirmModalState',
  default: false,
})

export const isOpenAskToCancelConfirmModalState = atom<boolean>({
  key: 'isOpenAskToCancelConfirmModalState',
  default: false,
})

export const isDefaultMethodLoadedState = atom<boolean>({
  key: 'isDefaultMethodLoadedState',
  default: false,
})

export const isPlacingOrderState = atom<boolean>({
  key: 'isPlacingOrderState',
  default: false,
})

export const deviceDataState = atom<string>({
  key: 'deviceDataState',
  default: '',
})

export const stripeRadarSessionState = atom<string | null>({
  key: 'stripeRadarSessionState',
  default: null,
})

export const isOpenLimitedTimeOfferModalState = atom<boolean>({
  key: 'isOpenLimitedTimeOfferModalState',
  default: false,
})

export const isOpenDowntimeModalState = atom<boolean>({
  key: 'isOpenDowntimeModalState',
  default: false,
})

export const isOpenLoyaltyInfoModal = atom<boolean>({
  key: 'isOpenLoyaltyInfoModal',
  default: false,
})

export const isMobileModal = atom<boolean>({
  key: 'isMobileModal',
  default: false,
})

export const questProgressState = atom<number | undefined>({
  key: 'questProgressState',
  default: undefined,
})

export const freeGameState = atom<TotalAmountResponse | undefined>({
  key: 'freeGameState',
  default: undefined,
})

export const isOpenConfirmSelectTeammateState = atom<boolean>({
  key: 'isOpenConfirmSelectTeammateState',
  default: false,
})

export const errorToastState = atom<string[]>({
  key: 'errorToastState',
  default: [],
})

export const isOpenLobbyBackConfirmState = atom<boolean>({
  key: 'isOpenLobbyBackConfirmState',
  default: false,
})

export const selectTeamState = atom<TeamResponse>({
  key: 'selectTeamIdState',
  default: { id: '-1' } as TeamResponse,
})

export const enableCustomizeLobbyButtonState = atom<boolean>({
  key: 'enableCustomizeLobbyButtonState',
  default: false,
})

export const enableVoiceChatOffToggleButtonState = atom<boolean>({
  key: 'enableVoiceChatOffToggleButtonState',
  default: false,
})

export const selectedChampionsState = atom<ChampionResponse[]>({
  key: 'selectedChampionsState',
  default: [],
})
